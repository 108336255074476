import { template as template_995d243733a54d4d919f707536161e0c } from "@ember/template-compiler";
const FKLabel = template_995d243733a54d4d919f707536161e0c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
